import React, { useCallback } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { supportedWallet, WALLETS } from 'data/supportedWallet';
import { useConnect } from 'wagmi';
import { toast } from 'react-toastify';
import { networkConfig } from 'config';
import { appActions } from 'store/app';
import { getErrorMessage } from 'data/errors';

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	margin-bottom: 25px;
	align-items: center;

	:hover {
		cursor: pointer;
	}
`;

const Available = styled(Typography)`
	background-color: #4bb543;
	padding: 4px 7px;
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
	font-size: 12px;
`;

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 350,
	backgroundColor: 'white',
	boxShadow: 24,
	borderRadius: '24px',
	p: 4,
	outline: 'none',
	minHeight: 300,
};

const titleBox = {
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
};

const containerStyle = {
	display: 'flex',
	justifyContent: 'space-between',
};

const WalletConnectModal: React.ComponentType<{}> = () => {
	const dispatch = useDispatch();
	const { connect, connectors } = useConnect();
	const { wallets } = useSelector((state: RootState) => state.vault);
	const { selectedNetwork, isWalletOpen } = useSelector(
		(state: RootState) => state.app,
	);
	const { terms } = useSelector((state: RootState) => state.app);
	const isWalletInstalled = useCallback(
		(connectorId: string): boolean => {
			const connector = connectors.find((c) => c.id === connectorId);
			return !!connector;
		},
		[connectors],
	);

	const installedWallet = wallets.filter((d) => isWalletInstalled(d));
	const availableWallet = wallets.filter((d) => !isWalletInstalled(d));

	const onClose = useCallback(() => {
		dispatch(appActions.openWallet(false));
	}, [dispatch]);

	const onWalletClick = useCallback(
		(id: string, getWallet: string) => {
			const selectedConnector = connectors.find((f) => f.id === id);

			if (selectedConnector) {
				connect(
					{
						connector: selectedConnector,
						chainId: networkConfig[selectedNetwork]
							.chainId as number,
					},
					{
						onSuccess: () => {
							onClose?.();
							if (!terms.isAccepted) {
								dispatch(
									appActions.setAccepted({
										open: true,
										value: false,
									}),
								);
							}
						},
						onError: (e: any) => {
							toast(
								getErrorMessage(e?.code, e) ||
									e.details ||
									'Something went wrong',
								{
									type: 'error',
								},
							);
						},
					},
				);
			} else {
				window.open(getWallet, '_blank');
			}
		},
		[
			connect,
			connectors,
			dispatch,
			onClose,
			selectedNetwork,
			terms.isAccepted,
		],
	);

	const RenderItem = useCallback(
		({ d }: { d: WALLETS }) => {
			const { icon, name, id, getWallet } = supportedWallet[d];
			const installed = isWalletInstalled(id);

			return (
				<Row onClick={() => onWalletClick(id, getWallet)}>
					<img width={35} height={35} alt={d} src={icon} />
					<Box width={14} />
					<Typography fontWeight={'bold'} display={'inline'}>
						{name}{' '}
						{installed ? (
							''
						) : (
							<Available display={'inline'}>
								Not Installed
							</Available>
						)}
					</Typography>
				</Row>
			);
		},
		[isWalletInstalled, onWalletClick],
	);

	return (
		<Modal
			open={isWalletOpen}
			onClose={onClose}
			aria-labelledby="custom-modal-title"
			aria-describedby="custom-modal-description"
			disableAutoFocus
			disableEnforceFocus
		>
			<Box sx={style}>
				<Box sx={containerStyle}>
					<Box sx={titleBox}>
						<Typography
							id="custom-modal-title"
							variant="subtitle2"
							component="h2"
							fontWeight={600}
							alignSelf={'center'}
							color={'rgba(157, 108, 15, 0.8)'}
						>
							Choose your preferred wallet
						</Typography>
						<Box height={10} />
						<>
							{installedWallet.map((d) => (
								<RenderItem key={d} d={d} />
							))}

							<Box height={26} />

							{availableWallet.map((d) => (
								<RenderItem key={d} d={d} />
							))}
						</>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

export default WalletConnectModal;
