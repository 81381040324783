import React from 'react';
import { NewDashboard } from 'pages/NewDashboard';

export const Landing = () => {
	return (
		<>
			<NewDashboard />
		</>
	);
};
