import React, { useCallback, useEffect, useState } from 'react';
import { AmountInputBox } from 'pages/Landing/components/AmountInputBox';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useNetwork } from 'hooks/useNetwork';
import { getErc20Contract } from 'utils/contractHelpers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { getDepositContract } from 'utils/depositContractHelpers';
import { toast } from 'react-toastify';
import { userDeposit } from 'apis';
import { useEthersProvider } from 'hooks/useEtherProvider';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { useAccount } from 'wagmi';
import { appActions } from 'store/app';
import { getErrorMessage } from 'data/errors';
import { useLocation } from 'react-router-dom';
import { SOCIAL } from '../../../data/socials';
import { fetchIpInformation } from 'utils/geolocation';
import { RESTRICTED_REGION } from 'data/geoLocation';

const Column = styled('div')`
	display: flex;
	flex-direction: column;
`;

const SpaceBetween = styled(Column)`
	height: 100%;
	justify-content: space-between;
`;

const StyledButton = styled(Button)<{ disabled?: boolean }>`
	display: flex;
	padding: 12px 32px;
	box-shadow: 0 4px 4px transparent;
	border-radius: 8px;
	overflow: hidden;
	background-color: ${({ disabled }) => (disabled ? '#dadada' : '#b9886d')};

	:hover {
		background-color: ${({ disabled }) =>
			disabled ? '#dadada' : '#bb805f'};
		cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
		box-shadow: 0 4px 4px transparent;
	}
`;

const twitterSocial = SOCIAL.find((x) => x.name.toLowerCase() === 'twitter');

export const DepositPanel: React.ComponentType<{ onSuccess?(): void }> = ({
	onSuccess,
}) => {
	const [loading, setLoading] = useState(false);
	const [allowance, setAllowance] = useState('0');
	const [balance, setBalance] = useState('0');
	const [contractBal, setContractBal] = useState('0');
	const [amount, setAmount] = useState('');

	const { address: account, isConnected } = useAccount();
	const dispatch = useDispatch();
	const location = useLocation();
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const selectedExchange = selectedVault.exchanger.find(
		(d) => d.value === selectedVault.exchange,
	);
	const queryParams = new URLSearchParams(location.search);
	const devMode = queryParams.get('devmode');
	const minAllowed = devMode ? 0.1 : selectedAsset?.minAllowed || 0;

	useNetwork();

	const provider: any = useEthersProvider();
	const signer: any = useEthersSigner();

	// Check if deposit should be disabled based on min/max allowed
	const isDisabled =
		parseFloat(amount || '0') >= (selectedAsset?.maxAllowed || 10000) ||
		parseFloat(amount || '0') < minAllowed;
	const disabledMessage =
		Number(amount) < minAllowed
			? `Min ${minAllowed} USDC allowed`
			: `${minAllowed}-${
					selectedAsset?.maxAllowed || 10000
			  } Currently in beta testing`;

	const formattedBalance = Number(balance).toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	const formattedContractBal = Number(contractBal).toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	const fetchAllowance = useCallback(async () => {
		try {
			if (account && selectedAsset && selectedExchange) {
				const assetContract = getErc20Contract(
					selectedAsset.address,
					provider.getSigner(account).connectUnchecked(),
				);
				const assetAllowance = await assetContract.allowance(
					account,
					selectedExchange.contractAddress,
				);
				setAllowance(
					formatUnits(assetAllowance, selectedAsset.decimalValue) ||
						'0',
				);
			} else {
				setAllowance('0');
			}
		} catch {
			// handle error silently
		}
	}, [account, provider, selectedAsset, selectedExchange]);

	const fetchBalance = useCallback(async () => {
		try {
			if (account && selectedAsset && signer) {
				const assetContract = getErc20Contract(
					selectedAsset.address,
					signer.provider.getSigner(account).connectUnchecked(),
				);
				const assetBalance = await assetContract.balanceOf(account);
				setBalance(
					formatUnits(assetBalance, selectedAsset.decimalValue),
				);
			} else {
				setBalance('0');
			}
		} catch {
			// handle error silently
		}
	}, [account, signer, selectedAsset]);

	const fetchContractBalance = useCallback(async () => {
		try {
			if (account && selectedExchange && selectedAsset && signer) {
				dispatch(
					appActions.setLoading({
						loading: true,
						message: 'Please Wait...',
					}),
				);
				const contract = getDepositContract(
					selectedExchange.contractAddress || '',
					signer.provider.getSigner(account).connectUnchecked(),
				);

				// fetch user's contract balance
				const userContractBalance = await contract.balanceOf(account);
				const userContractBal = await contract.convertToAssets(
					userContractBalance,
				);
				setContractBal(
					formatUnits(userContractBal, selectedAsset.decimalValue) ||
						'0',
				);
			} else {
				setContractBal('0');
			}
		} catch {
			// handle error silently
		} finally {
			dispatch(appActions.setLoading({ loading: false, message: '' }));
		}
	}, [account, dispatch, selectedExchange, signer, selectedAsset]);

	const onDeposit = async () => {
		try {
			setLoading(true);

			if (!account || !selectedAsset || !selectedExchange || !signer)
				return;

			const res = await fetchIpInformation();
			if (res) {
				toast(
					'We regret to inform you that our services are currently\n' +
						'unavailable in certain regions due to regulatory and\n' +
						'compliance constraints. The restricted regions include:' +
						RESTRICTED_REGION.toString().replace(',', ', '),
					{
						type: 'error',
					},
				);
				return;
			}

			const assetContract = getErc20Contract(
				selectedAsset.address,
				signer.provider.getSigner(account).connectUnchecked(),
			);

			const assetBalance = await assetContract.balanceOf(account);
			const requiredAmount = parseUnits(
				amount,
				selectedAsset.decimalValue,
			);

			if (assetBalance.lt(requiredAmount)) {
				toast('Wallet does not have sufficient funds to deposit.', {
					type: 'error',
				});
				return;
			}

			const contract = getDepositContract(
				selectedExchange.contractAddress || '',
				signer.provider.getSigner(account).connectUnchecked(),
			);

			if (parseFloat(allowance) < parseFloat(amount)) {
				const tx = await assetContract.approve(
					selectedExchange.contractAddress,
					requiredAmount,
				);
				await assetContract.provider.waitForTransaction(tx.hash);

				const updatedAllowance = await assetContract.allowance(
					account,
					selectedExchange.contractAddress,
				);

				if (updatedAllowance.lt(requiredAmount)) {
					toast('Please allow USDC spending', { type: 'error' });
					return;
				}
			}

			const dpt = await contract.deposit(requiredAmount, account);
			await dpt.wait();

			await userDeposit({
				contract: selectedExchange.contractAddress || '',
				address: account,
				amount,
				token: 'USDC',
				eventType: 'deposit',
			});

			toast('Successfully staked into vault contract', {
				type: 'success',
			});
			onSuccess?.();
			setAmount('0');
		} catch (e: any) {
			toast(
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.75rem',
					}}
				>
					{/* The main error message */}
					<div>
						{getErrorMessage(e?.error?.code || e?.code, e) ||
							'Something went wrong'}
					</div>

					{/* Conditionally render the Support button at the bottom */}
					{getErrorMessage(e?.error?.code || e?.code, e).includes(
						'Something went wrong',
					) && (
						<StyledButton
							onClick={() => {
								window.open(
									'https://t.me/+96H-v4meO1szMmJk',
									'_blank',
								);
							}}
							style={{ height: 'min-content', padding: 5 }}
						>
							<Typography
								variant="caption"
								fontWeight="bold"
								py="6px"
								color="#fff"
								style={{ lineHeight: '120%' }}
							>
								Support
							</Typography>
						</StyledButton>
					)}
				</div>,
				{
					type: 'error',
					// Use the default react-toastify close button at top-right,
					// or set closeButton: false if you want to remove it
				},
			);
		} finally {
			fetchContractBalance();
			fetchBalance();
			fetchAllowance();
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchContractBalance();
		fetchAllowance();
		fetchBalance();
	}, [fetchAllowance, fetchBalance, fetchContractBalance]);

	return (
		<SpaceBetween>
			<Column>
				<AmountInputBox
					currency={{
						icon: selectedVault.token[0].icon,
						name: selectedVault.token[0].name,
					}}
					text={amount}
					onChange={setAmount}
					leftTxt={`${selectedVault.currency} ${formattedBalance}`}
					rightTxt={`${selectedVault.currency} ${formattedContractBal}`}
				/>
				<Box height={20} />
				<StyledButton
					onClick={() => {
						if (!isConnected) {
							dispatch(appActions.openDrawer(true));
						} else if (selectedExchange?.depositDisabled) {
							toast('Service is currently unavailable.', {
								type: 'default',
							});
						} else {
							onDeposit();
						}
					}}
					disabled={
						loading ||
						(isConnected && parseFloat(amount || '0') === 0) ||
						(isConnected && isDisabled)
					}
					sx={{ height: 52 }}
					fullWidth
				>
					<Typography
						variant={'button'}
						fontWeight="bold"
						py="6px"
						color={'#fff'}
						style={{ lineHeight: '120%' }}
					>
						{!isConnected
							? 'Connect'
							: isDisabled
							? disabledMessage
							: loading
							? 'Please wait...'
							: 'Deposit'}
					</Typography>
				</StyledButton>
				<br />
				<StyledButton
					onClick={() => {
						window.open(twitterSocial?.url, '_blank');
					}}
				>
					<Typography
						variant={'button'}
						fontWeight="bold"
						py="6px"
						color={'#fff'}
						style={{ lineHeight: '120%' }}
					>
						Engage with {selectedVault.name}
					</Typography>
				</StyledButton>
			</Column>
		</SpaceBetween>
	);
};
