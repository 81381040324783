import { Box, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { getUserDeposit, getUserEarned } from 'apis';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useAccount } from 'wagmi';
import { formatNumberToCurrencyView } from 'utils/formatCurrency';
import { ParseFloat } from 'utils/parseFloat';
import { formatNumber } from 'utils/formatNumber';
import moment from 'moment';

const GridItem = styled('div')`
	display: flex;
	flex-direction: column;

	align-items: center;
	border-right: 1px solid #b9886d;

	&:last-child {
		border-right: none;
	}

	@media (max-width: 1100px) {
		&:nth-child(even) {
			border-right: none;
		}
	}
`;

const Grid = styled('div')`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	padding: 40px 20px;
	column-gap: 20px;

	@media (max-width: 1100px) {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 10px;
		row-gap: 30px;
	}
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const StyledBox = styled('div')(() => ({
	display: 'flex',
	padding: '6px 13px',
	justifyContent: 'center',
	alignItems: 'baseline',
	backgroundColor: '#b9886d',
	borderRadius: 8,
	width: 'min-content',
	height: 'min-content',
	marginLeft: 10,
}));

export const Header: React.FC<{
	stat: {
		interest_value: number;
		pnl: string;
		portfolio_value: string;
		volume_trade: string;
	};
	contractBal: string;
}> = ({ stat, contractBal }) => {
	const [earning, setEarning] = useState({
		totalEarning: 0,
	});
	const [lockedTime, setLockedTime] = useState<string | undefined>(undefined);
	const appVault = useSelector((state: RootState) => state.vault);
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedContract = selectedVault.exchanger.find(
		(d) => d.value === appVault.exchange,
	);
	const { address: account } = useAccount();
	const payload = useMemo(() => {
		return {
			contract: selectedContract?.contractAddress || '',
			address: account || '',
		};
	}, [account, selectedContract?.contractAddress]);

	const fetchEarned = useCallback(async () => {
		try {
			if (account) {
				const res = await getUserEarned(account);
				if (res.length > 0) {
					setEarning(res[0]);
				}
			} else {
				setEarning({ totalEarning: 0 });
			}
		} catch (e) {
			//
		}
	}, [account]);

	const fetchLockedTime = useCallback(async () => {
		try {
			const res = await getUserDeposit(payload);
			if (res.lastDeposit.length > 0) {
				setLockedTime(res.lastDeposit[0].createdAt);
			}
		} catch (e) {
			//
		}
	}, [payload]);

	const liveInterestRate = useMemo(() => {
		const av = parseFloat(stat.portfolio_value);
		const pnl = parseFloat(stat.pnl.replace(/[$,]/g, ''));

		if (!stat || isNaN(av) || isNaN(pnl)) {
			return null;
		}

		const daysSinceCutoff = moment
			.utc()
			.startOf('day')
			.diff(
				moment.utc('18-12-2024', 'DD-MM-YYYY').startOf('day'),
				'days',
			);

		return `${(100 * (pnl / av) * (365 / daysSinceCutoff)).toFixed(2)}%`;
	}, [stat]);

	useEffect(() => {
		fetchLockedTime();
	}, [fetchLockedTime]);

	useEffect(() => {
		fetchEarned();
	}, [fetchEarned]);

	return (
		<Grid>
			<GridItem>
				<Typography
					variant="h6"
					fontWeight={500}
					display={'inline'}
					color={'#777'}
				>
					TVL
				</Typography>
				<Box height={15} />
				<Typography variant="h4" fontWeight={500} display={'inline'}>
					$
					{formatNumberToCurrencyView(
						ParseFloat(
							stat.portfolio_value.slice(
								0,
								stat.portfolio_value.indexOf('.') + 3,
							) || '0',
						),
					)}
				</Typography>
			</GridItem>
			<Tooltip
				title="Past month return is the annualized return of the vault based on the past month to reduce noise. Return is calculated based on the overall profit from strategies executed by Dudu. Past returns are not indicative of future performance."
				arrow
				placement="top" // Always open on top
				componentsProps={{
					tooltip: {
						sx: {
							backgroundColor: '#f5f1e9',
							border: '1px solid rgb(185, 136, 109)',
							color: '#000',
						},
					},
					arrow: {
						sx: {
							color: 'rgb(185, 136, 109)', // Match arrow color to tooltip background
						},
					},
				}}
			>
				<GridItem>
					<Typography
						variant="h6"
						fontWeight={500}
						display={'inline'}
						color={'#777'}
					>
						Past Month Return
					</Typography>
					<Box height={15} />
					<Row>
						<Typography
							variant="h4"
							fontWeight={500}
							display={'inline'}
						>
							{liveInterestRate ?? '-'}
						</Typography>
						{liveInterestRate && (
							<StyledBox>
								<Typography
									variant="body1"
									color="#fff"
									fontWeight={800}
									display={'inline'}
								>
									APR
								</Typography>
							</StyledBox>
						)}
					</Row>
				</GridItem>
			</Tooltip>

			<GridItem>
				<Typography
					variant="h6"
					fontWeight={500}
					display={'inline'}
					color={'#777'}
				>
					Your Deposits
				</Typography>
				<Box height={15} />
				<Typography variant="h4" fontWeight={500} display={'inline'}>
					${contractBal}
				</Typography>
			</GridItem>
			<GridItem>
				<Typography
					variant="h6"
					fontWeight={500}
					display={'inline'}
					color={'#777'}
				>
					All-time Earned
				</Typography>
				<Box height={15} />
				<Typography variant="h4" fontWeight={500} display={'inline'}>
					{earning.totalEarning === 0
						? '-'
						: `$${formatNumber(earning.totalEarning)}`}
				</Typography>
			</GridItem>
		</Grid>
	);
};
