import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { UserWithdrawDeposit } from 'apis';

function formatClaimTimePeriod(period = '') {
	if (period === '1m') {
		return '1 month';
	}
	return period.replace('m', ' months');
}

export const ConfirmationModal = ({
	open,
	onClose,
	onConfirm,
	info,
}: {
	open: boolean;
	onClose(): void;
	onConfirm(): void;
	info: UserWithdrawDeposit;
}) => {
	const handleClose = () => {
		onClose();
	};

	return (
		<React.Fragment>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Claim'}</DialogTitle>
				{info.claimTimePeriod && (
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to claim ${info.amount} in{' '}
							{formatClaimTimePeriod(info.claimTimePeriod)} time
							period?
						</DialogContentText>
					</DialogContent>
				)}
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={onConfirm} autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};
