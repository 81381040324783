// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import axios from 'axios';
import moment from 'moment';

export const calcMaxdrawdown = async () => {
	// inline function definitions
	const findCutoffIndex = (data, cutoff) => {
		let left = 0;
		let right = data.length - 1;
		let cutoffIndex = data.length;

		while (left <= right) {
			const mid = Math.floor((left + right) / 2);
			const createdAt = moment.utc(data[mid].createdAt);

			if (createdAt.isBefore(cutoff)) {
				left = mid + 1;
			} else {
				cutoffIndex = mid;
				right = mid - 1;
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return cutoffIndex;
	};

	const timePeriodsInDays = [7];

	const { data } = await axios.get(
		'https://stats-apis.rndm.io/api/all-stats',
	);
	data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

	const currentAccountValue = data[data.length - 1].portfolio_value;

	const stats = [];
	for (const timePeriod of timePeriodsInDays) {
		const now = moment().utc();
		const timePeriodDaysAgo = now.clone().subtract(timePeriod, 'days');

		const startIndex = findCutoffIndex(data, timePeriodDaysAgo);
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const data_subset = data.slice(startIndex);

		let max = Number.NEGATIVE_INFINITY;
		let min = Number.POSITIVE_INFINITY;

		for (const datum of data_subset) {
			const intermediateValue = datum.portfolio_value;

			if (max < intermediateValue) {
				max = intermediateValue;
			}
			if (min > intermediateValue) {
				min = intermediateValue;
			}
		}

		const drawdown = (max - min) / currentAccountValue;

		stats.push({
			durationInDays: timePeriod,
			peak: max,
			min,
			current: currentAccountValue,
			drawdown,
		});
	}

	return stats;
};
