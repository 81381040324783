import React from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CLAIM } from 'data/claim';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';

const Row = styled('div')`
	display: flex;
	flex-direction: column;
`;

export const ClaimModal = ({
	open,
	setClose,
}: {
	open: boolean;
	setClose(period?: string): void;
}) => {
	const handleClose = (period?: string) => setClose(period);

	return (
		<Modal
			open={open}
			onClose={(event, reason) => {
				if (reason === 'backdropClick') return;
				handleClose();
			}}
			aria-labelledby="modal-title"
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					bgcolor: 'background.paper',
					border: '2px solid #000',
					boxShadow: 24,
					p: 3,
					borderRadius: '8px',
					outline: 'none',
				}}
			>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					sx={{ mb: 2 }}
				>
					<Box
						display="flex"
						alignItems="center"
						flexDirection={'row'}
					>
						<Typography
							id="modal-title"
							variant="h6"
							component="h2"
						>
							Claim
						</Typography>
						<Box width={5} />
						<Row
							onClick={() =>
								window.open(
									'https://docs-dudu.rndm.io/how-to-use-dudu-ai-agent-vault',
									'_blank',
								)
							}
						>
							<InfoIcon
								sx={{ fontSize: 20, cursor: 'pointer' }}
							/>
						</Row>
					</Box>
					<IconButton
						onClick={() => handleClose()}
						sx={{
							color: 'inherit',
						}}
					>
						<CloseIcon />
					</IconButton>
				</Box>

				<Box
					display="flex"
					justifyContent="space-between"
					flexDirection="column"
				>
					{CLAIM.map((m) => (
						<>
							<Button
								variant={m.variant as 'text' | 'contained'}
								color="primary"
								onClick={() => handleClose(m.value)}
								size={'large'}
							>
								{m.title}
							</Button>
							<Box height={10} />
						</>
					))}
				</Box>
			</Box>
		</Modal>
	);
};
