export const SOCIAL = [
	{
		id: 1,
		name: 'Twitter',
		url: 'https://x.com/dudu_rndm',
		icon: '/twitter.png',
	},
	{
		id: 2,
		name: 'Discord',
		url: 'https://discord.com/channels/867348548018634753/1291343188480622735',
		icon: '/discord.png',
	},
	{
		id: 1,
		name: 'Telegram',
		url: 'https://t.me/+96H-v4meO1szMmJk',
		icon: '/telegram.svg',
	},
];
