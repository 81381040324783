export const BLOCKING_LOCATION = [
	'CA',
	'GB',
	'BY',
	'CU',
	'IR',
	'KP',
	'RU',
	'SY',
	'UA',
	'US',
];

export const RESTRICTED_REGION = [
	'Belarus',
	'Canada',
	'Cuba',
	'Iran',
	'North Korea',
	'Russia',
	'Syria',
	'Ukraine',
	'United States of America',
	'United Kingdom',
];
