import moment from 'moment';

export const hasOneHourPassed = (startTime: string) => {
	const currentTime = moment();
	const startTimeMoment = moment(startTime);

	const differenceInHours = currentTime.diff(startTimeMoment, 'hours');

	return differenceInHours < 1;
};

export const hasXWeekPassed = (startTime: string, week: number) => {
	const currentTime = moment();
	const startTimeMoment = moment(startTime);

	const differenceInMinutes = currentTime.diff(startTimeMoment, 'week');

	return differenceInMinutes < week;
};

export const getLockMessage = (canWithdraw?: boolean) => {
	const forcedUnlockMoment = moment(
		'2025-01-06 00:00:00',
		'YYYY-MM-DD HH:mm:ss',
	);
	const now = moment();

	if (now.isBefore(forcedUnlockMoment)) {
		return `Claim at ${forcedUnlockMoment.format('DD-MMM, hh:mm A')}`;
	}

	return canWithdraw ? 'Withdraw' : 'Claim';
};

export const isLocked = () => {
	const forcedUnlockMoment = moment(
		'2025-01-06 00:00:00',
		'YYYY-MM-DD HH:mm:ss',
	);
	const now = moment();

	return now.isBefore(forcedUnlockMoment);
};

export const checkLock = (canWithdraw?: boolean) => {
	return getLockMessage(canWithdraw);
};

export const adjustDate = (isoDateString: string) => {
	const date = moment(isoDateString);
	const dayOfMonth = date.date();

	if (dayOfMonth <= 14) {
		date.add(1, 'month');
	} else {
		date.add(2, 'month').startOf('month');
	}

	return date.toISOString();
};
