import React, { useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Modal,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { appActions } from 'store/app';
import { useAccount, useDisconnect } from 'wagmi';

const StyledLink = styled(Link)(({ theme }) => ({
	color: '#3A3A3A',
	cursor: 'pointer',
	textDecoration: 'none',
	fontWeight: 600,

	'&:hover': {
		color: '#000',
	},

	'&.active': {
		color: '#b9886d',
	},
}));

const TermsOfUseModal = () => {
	const { terms } = useSelector((state: RootState) => state.app);
	const [acknowledge, setAcknowledge] = useState(false);
	const [accept, setAccept] = useState(false);
	const dispatch = useDispatch();
	const { isConnected } = useAccount();
	const { disconnect } = useDisconnect();

	return (
		<Modal
			open={
				(terms.isOpen && isConnected) ||
				(!terms.isAccepted && isConnected)
			}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					dispatch(
						appActions.setAccepted({
							open: false,
							value: false,
						}),
					);
				}
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					bgcolor: 'background.paper',
					boxShadow: 24,
					borderRadius: 2,
					p: 4,
					outline: 'none', // Remove blue outline
				}}
			>
				<Typography variant="h6" component="h2" sx={{ mb: 2 }}>
					Terms of Use
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							checked={acknowledge}
							onChange={(e) => setAcknowledge(e.target.checked)}
						/>
					}
					label="I acknowledge that I am trading with real money."
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={accept}
							onChange={(e) => setAccept(e.target.checked)}
						/>
					}
					label={
						<span>
							I accept the{' '}
							<StyledLink
								to={'/terms-and-condition'}
								target={'_blank'}
							>
								<span
									style={{
										color: '#b9886d',
										cursor: 'pointer',
									}}
								>
									Terms of Use
								</span>
							</StyledLink>
							.
						</span>
					}
				/>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						mt: 3,
					}}
				>
					<Button
						variant="outlined"
						color="primary"
						onClick={() => {
							disconnect();
							dispatch(
								appActions.setAccepted({
									open: false,
									value: false,
								}),
							);
						}}
					>
						Reject
					</Button>
					<Button
						variant="contained"
						color="primary"
						disabled={!acknowledge || !accept}
						onClick={() => {
							localStorage.setItem('termsCondition', 'true');
							dispatch(
								appActions.setAccepted({
									open: false,
									value: true,
								}),
							);
						}}
					>
						Accept
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default TermsOfUseModal;
