import React from 'react';
import { Box } from '@mui/material';

import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { ConnectionButton } from 'components/Navbar/ConnectButton';
import { Gutter } from 'components/Gutter';
import { NAVBAR } from 'data/navbar';
import { SOCIAL } from 'data/socials';
import { Link } from 'react-router-dom';

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const navContainer = css`
	height: 65px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 40px;
`;

const Image = styled('img')`
	max-width: 25px;
	max-height: 22px;
	min-height: 22px;
	min-width: 25px;
	position: relative;
	width: 100%;
	height: 100%;

	:hover {
		cursor: pointer;
	}

	& img:hover {
		filter: invert(44%) sepia(99%) saturate(6152%) hue-rotate(220deg)
			brightness(95%) contrast(106%);
	}
`;

const StyledLink = styled(Link)(({ theme }) => ({
	color: '#3A3A3A',
	margin: theme.spacing(2),
	cursor: 'pointer',
	textDecoration: 'none',
	fontWeight: 600,
	marginRight: 2,
	'&:hover': {
		color: '#000',
	},

	'&.active': {
		color: '#2960F8',
	},
}));

const StyledAnchor = styled('a')(({ theme }) => ({
	color: '#3A3A3A',
	margin: theme.spacing(2),
	cursor: 'pointer',
	textDecoration: 'none',
	fontWeight: 600,
	marginRight: 2,
	'&:hover': {
		color: '#000',
	},

	'&.active': {
		color: '#2960F8',
	},
}));

const NavContainer = styled(Box)`
	${navContainer}
`;

const Navbar: React.ComponentType = () => {
	return (
		<>
			<NavContainer
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<Row>
					<Link to={'/'}>
						<img
							src="/images/rndm-logo.png"
							height={36}
							alt={'movement'}
						/>
					</Link>
					<Box width={64} />
				</Row>

				<Box display="flex" alignItems="center">
					{SOCIAL.map((social) => (
						<a
							href={social.url}
							target="_blank"
							key={social.name}
							style={{ marginRight: 16 }}
						>
							<Image
								src={social.icon}
								alt={social.name}
								width={32}
								height={24}
							/>
						</a>
					))}
					<Box width={16} />
					{NAVBAR.map((d) => {
						return d.target ? (
							<StyledAnchor
								key={d.to}
								target={d.target}
								href={d.to}
							>
								{d.name}
							</StyledAnchor>
						) : (
							<StyledLink key={d.to} to={d.to}>
								{d.name}
							</StyledLink>
						);
					})}
					{/*{isConnected && (
						<StyledLink to={'/positions'}>Positions</StyledLink>
					)}*/}
					<Gutter gap={1} />
					<ConnectionButton />
				</Box>
			</NavContainer>
		</>
	);
};

export { Navbar };
