type Suffix = {
	value: number;
	symbol: string;
};

export function formatNumber(num: string | number): string {
	const numericValue = parseFloat(num.toString().replace(/[^\d.-]/g, ''));
	if (isNaN(numericValue)) return '';

	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	}).format(numericValue);
}

export function formatNumberFromCurrency(num: number) {
	return new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	}).format(num);
}

export const formatNumberToCurrencyView = (num: number): string => {
	// Runtime check, even though 'num' is typed as number at compile time.
	if (typeof num !== 'number' || isNaN(num)) {
		throw new Error('Input must be a valid number');
	}

	const suffixes: Suffix[] = [
		{ value: 1_000_000_000, symbol: 'B' },
		{ value: 1_000_000, symbol: 'M' },
		{ value: 1_000, symbol: 'K' },
	];

	const suffix = suffixes.find(({ value }) => Math.abs(num) >= value);

	if (suffix) {
		return `${(num / suffix.value).toFixed(2).replace(/\.?0+$/, '')}${
			suffix.symbol
		}`;
	}

	return num.toString();
};
