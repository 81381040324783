import * as React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Landing } from 'pages';
import { Navbar } from 'components';
import LoadingDialog from 'components/Loader';
import { useAccount, useSwitchChain } from 'wagmi';
import { networkConfig } from 'config';
import { TermsAndCondition } from 'pages/TermsAndCondition';
import TermsAndConditionModal from 'pages/TermsAndConditionModal';

const DApp: React.ComponentType = () => {
	const { isConnected, chain } = useAccount();
	const { switchChain } = useSwitchChain();

	React.useEffect(() => {
		if (isConnected && chain === undefined) {
			switchChain({ chainId: networkConfig.Base.chainId });
		}
	}, [chain, isConnected, switchChain]);

	return (
		<Router>
			<div>
				<LoadingDialog />
				<TermsAndConditionModal />
				{/*<GeoBlocking />*/}
				<>
					<Navbar />
					<Switch>
						{/*{isConnected && (
							<Route path="/positions">
								<Positions />
							</Route>
						)}*/}
						<Route path="/terms-and-condition">
							<TermsAndCondition />
						</Route>
						<Route path="/">
							<Landing />
						</Route>
					</Switch>
				</>
			</div>
		</Router>
	);
};

export default DApp;
