import axios from 'axios';

export const postData = async ({
	message,
	code,
}: {
	message: string;
	code: number;
}) => {
	try {
		await axios.post('https://apis.vcred.trade/api/report-on-slack', {
			message: `CODE:${code}\n MESSAGE:${message}`,
		});
	} catch (error: any) {
		await axios.post('https://apis.vcred.trade/api/report-on-slack', {
			message: `Error: ${JSON.stringify(error)}`,
		});
	}
};
