import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { SmallItemCard } from 'pages/NewDashboard/components/SmallItemCard';
import { Box, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNetwork } from 'hooks/useNetwork';
import { getErc20Contract } from 'utils/contractHelpers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { getDepositContract } from 'utils/depositContractHelpers';
import { toast } from 'react-toastify';
import {
	getClaimList,
	getUserDeposit,
	userDeposit,
	UserWithdrawDeposit,
	userWithdrawTransaction,
} from 'apis';
import {
	adjustDate,
	checkLock,
	hasOneHourPassed,
	isLocked,
} from 'utils/lockDuration';
import moment from 'moment';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { useAccount } from 'wagmi';
import { appActions } from 'store/app';
import { getErrorMessage } from 'data/errors';
import { AmountBox } from 'pages/Landing/components/AmountBox';
import { ClaimModal } from 'pages/Landing/components/ClaimModal';
import { ConfirmationModal } from 'pages/Landing/components/ConfirmationModal';

const Column = styled('div')`
	display: flex;
	flex-direction: column;
`;

const SpaceBetween = styled(Column)`
	height: 100%;
	justify-content: space-between;
`;

const StyledButton = styled(Button)<{ disabled?: boolean }>`
	display: flex;
	padding: 12px 32px;
	box-shadow: 0 4px 4px transparent;
	border-radius: 8px;
	overflow: hidden;
	background-color: ${({ disabled }) => (disabled ? '#dadada' : '#b9886d')};

	:hover {
		background-color: ${({ disabled }) =>
			disabled ? '#dadada' : '#bb805f'};
		cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
		box-shadow: 0 4px 4px transparent;
	}
`;

export const WithdrawPanel: React.ComponentType<{ onSuccess?(): void }> = ({
	onSuccess,
}) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationData, setConfirmationData] = useState({});
	const [claims, setClaims] = useState<UserWithdrawDeposit[]>([]);
	const [open, setOpen] = useState(false);
	const [lockedTime, setLockedTime] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [balance, setBalance] = useState('0');
	const [contractBal, setContractBal] = useState('0');
	const [amount, setAmount] = useState('');
	const { address: account, isConnected } = useAccount();
	const dispatch = useDispatch();
	const appVault = useSelector((state: RootState) => state.vault);
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const selectedContract = selectedVault.exchanger.find(
		(d) => d.value === appVault.exchange,
	);
	const canWithdraw = claims.some((item) => item.claimStatus === 'processed');

	const signer: any = useEthersSigner();
	const bal = parseFloat(parseFloat(balance).toFixed(2)).toLocaleString();
	const contractBalance = parseFloat(
		parseFloat(contractBal).toFixed(2),
	).toLocaleString();
	const isAccountConnected = isConnected;
	const payload = useMemo(() => {
		return {
			contract: selectedContract?.contractAddress || '',
			address: account || '',
		};
	}, [account, selectedContract?.contractAddress]);

	useNetwork();

	const buttonLabel = useMemo(() => {
		if (!isAccountConnected) return 'Connect Wallet';

		if (loading) return 'Please wait...';

		if (claims.length > 0 && !canWithdraw) {
			const obj = claims[claims.length - 1];
			if (obj.claimTimePeriod === '1m') {
				return (
					'Claim on ' +
					moment(adjustDate(claims[0].createdAt || '')).format(
						'DD MMM YYYY',
					)
				);
			} else if (obj.claimTimePeriod === '3m') {
				return (
					'Claim on ' +
					moment(claims[0].createdAt || '')
						.add(3, 'months')
						.format('DD MMM YYYY')
				);
			} else if (obj.claimTimePeriod === '6m') {
				return (
					'Claim on ' +
					moment(claims[0].createdAt || '')
						.add(6, 'months')
						.format('DD MMM YYYY')
				);
			}

			return 'Claim under processing';
		}

		if (lockedTime && checkLock()) {
			return checkLock(canWithdraw);
		}

		return 'Claim';
	}, [canWithdraw, claims, isAccountConnected, loading, lockedTime]);

	const fetchBalance = useCallback(async () => {
		try {
			if (account) {
				const assetContract = getErc20Contract(
					selectedAsset?.address || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);
				const assetBalance = await assetContract.balanceOf(account);
				setBalance(
					formatUnits(assetBalance, selectedAsset?.decimalValue),
				);
			} else {
				setBalance('0');
			}
		} catch (e) {
			//
		}
	}, [account, signer, selectedAsset?.address, selectedAsset?.decimalValue]);

	const fetchClaims = useCallback(async () => {
		try {
			if (account) {
				const res = await getClaimList(
					selectedContract?.contractAddress || '',
					account,
				);

				setClaims(res);
			}
		} catch (e) {
			//
		}
	}, [account, selectedContract?.contractAddress]);

	const fetchContractBalance = useCallback(async () => {
		try {
			if (account) {
				dispatch(
					appActions.setLoading({
						loading: true,
						message: 'Please Wait...',
					}),
				);
				const contract = getDepositContract(
					selectedContract?.contractAddress || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);

				// fetch balance
				const userContractBalance = await contract.balanceOf(account);
				const userBal = await contract.convertToAssets(
					userContractBalance,
				);
				setContractBal(
					formatUnits(userBal, selectedAsset?.decimalValue) || '0',
				);
				setAmount(
					formatUnits(userBal, selectedAsset?.decimalValue) || '0',
				);
			} else {
				setContractBal('0');
			}
		} catch (e) {
			//
		} finally {
			dispatch(appActions.setLoading({ loading: false, message: '' }));
		}
	}, [
		account,
		selectedContract?.contractAddress,
		signer?.provider,
		selectedAsset?.decimalValue,
		dispatch,
	]);

	const withdrawTransaction = async () => {
		try {
			await userWithdrawTransaction(
				confirmationData as UserWithdrawDeposit,
			);

			await fetchClaims();
			setConfirmationOpen(false);
			setOpen(false);
		} catch (e) {
			//
		}
	};

	const withdraw = async () => {
		try {
			setLoading(true);

			const res = await getUserDeposit(payload);
			if (res.lastDeposit.length > 0) {
				setLockedTime(res.lastDeposit[0].createdAt);
				if (!hasOneHourPassed(res.lastDeposit[0].createdAt)) {
					const contract = getDepositContract(
						selectedContract?.contractAddress || '',
						signer?.provider.getSigner(account).connectUnchecked(),
					);

					const tx = await contract.withdraw(
						parseUnits(amount, selectedAsset?.decimalValue),
						account,
						account,
					);
					await tx.wait();
					await userDeposit({
						contract: selectedContract?.contractAddress || '',
						address: account || '',
						amount,
						token: 'USDC',
						eventType: 'withdraw',
					});
					toast('Successfully withdrawn from vault contract', {
						type: 'success',
					});
					setAmount('0');
					onSuccess?.();
					return;
				}
			}
			toast('Sorry your amount is locked for 1h', {
				type: 'error',
			});
		} catch (e: any) {
			toast(
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.75rem',
					}}
				>
					<div>
						{getErrorMessage(e?.error?.code || e?.code, e) ||
							'Something went wrong'}
					</div>
					{getErrorMessage(e?.error?.code || e?.code, e).includes(
						'Something went wrong',
					) && (
						<StyledButton
							onClick={() => {
								window.open(
									'https://t.me/+96H-v4meO1szMmJk',
									'_blank',
								);
							}}
							style={{ height: 'min-content', padding: 5 }}
						>
							<Typography
								variant="caption"
								fontWeight="bold"
								py="6px"
								color="#fff"
								style={{ lineHeight: '120%' }}
							>
								Support
							</Typography>
						</StyledButton>
					)}
				</div>,
				{
					type: 'error',
				},
			);
		} finally {
			fetchContractBalance();
			fetchBalance();
			setLoading(false);
		}
	};

	const fetchLockedTime = useCallback(async () => {
		const res = await getUserDeposit(payload);
		if (res.lastDeposit.length > 0) {
			setLockedTime(res.lastDeposit[0].createdAt);
		}
	}, [payload]);

	useEffect(() => {
		fetchContractBalance();
		fetchBalance();
		fetchLockedTime();
	}, [fetchBalance, fetchContractBalance, fetchLockedTime]);

	useEffect(() => {
		fetchClaims();
	}, [fetchClaims]);

	return (
		<>
			<SpaceBetween>
				<Column>
					<SmallItemCard />
					<Box height={16} />
					<AmountBox
						currency={{
							icon: selectedVault.token[0].icon,
							name: selectedVault.token[0].name,
						}}
						text={amount}
						leftTxt={`${selectedVault.currency} ${bal}`}
						rightTxt={
							selectedVault.currency + ' ' + contractBalance
						}
					/>

					<Box height={20} />
				</Column>
				<Column>
					<StyledButton
						variant={'contained'}
						onClick={() => {
							if (canWithdraw) {
								withdraw();
							} else {
								setOpen(true);
							}
						}}
						disabled={
							loading ||
							isLocked() ||
							parseFloat(amount || '0') === 0 ||
							contractBal < amount ||
							(claims.length === 0 ? false : !canWithdraw)
						}
					>
						<Typography fontWeight={600} color={'#fff'}>
							{buttonLabel}
						</Typography>
					</StyledButton>
					<Box height={32} />
				</Column>
			</SpaceBetween>
			<ConfirmationModal
				open={confirmationOpen}
				onClose={() => setConfirmationOpen(false)}
				onConfirm={() => {
					withdrawTransaction();
				}}
				info={confirmationData as UserWithdrawDeposit}
			/>
			<ClaimModal
				open={open}
				setClose={(period) => {
					if (period) {
						setConfirmationOpen(true);
						setConfirmationData({
							contract: selectedContract?.contractAddress || '',
							address: account || '',
							amount: contractBalance,
							token: 'USDC',
							eventType: 'claim',
							claimTimePeriod: period,
						});
					} else {
						setOpen(false);
					}
				}}
			/>
		</>
	);
};
