import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Chip,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import moment from 'moment';
import { ParseFloat } from 'utils/parseFloat';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'store/app';
import { RootState } from 'store';
import { formatNumberToCurrencyView } from 'utils/formatCurrency';
import { FREQUENCY } from 'data/frequency';

const StyledBox = styled(Box)`
	height: 250px;
	width: 450px;

	@media (max-width: 550px) {
		width: 250px;
	}
`;

const CustomChip = styled(Chip)<{ isSelected: boolean }>(({ isSelected }) => ({
	borderRadius: '20px',
	border: `1px solid ${isSelected ? '#000' : '#666'}`,
	backgroundColor: 'transparent',
	color: isSelected ? '#000' : '#666',
	fontWeight: isSelected ? '600' : '500',
	fontSize: '14px',
	marginRight: 10,
	'& .MuiChip-label': {
		padding: '0 8px',
	},
}));

type TabsType = 'av' | 'pnl';

const Tabs: { id: string; value: TabsType }[] = [
	{ id: 'Account Value', value: 'av' },
	{ id: 'PnL', value: 'pnl' },
];

const CustomCard = styled('div')`
	padding: 10px;
	background-color: rgb(185, 136, 109);
`;

export type PortfolioData = {
	_id: string;
	createdAt: string;
	day_stamp: string;
	interest_value: string;
	pnl: number;
	portfolio_value: number;
	volume_trade: string;
};

/**
 * Helper to find a "rounded" step size
 * based on the absolute value of the range (for negative or positive).
 */
function findBaseStep(num: number): number {
	const absNum = Math.abs(num);
	if (absNum <= 50_000) return 5_000;
	if (absNum <= 500_000) return 50_000;
	if (absNum <= 5_000_000) return 500_000;
	if (absNum <= 50_000_000) return 5_000_000;
	return 50_000_000;
}

/**
 * Compute 5 Y-axis ticks total, factoring in negative and positive values.
 * For example:
 *   - If min is -12000 and max is 8000,
 *   - We'll find a step size, then expand ticks from yMin to yMax in 4 intervals.
 */
function computeYAxisTicks(
	data: PortfolioData[],
	dataKey: 'pnl' | 'portfolio_value',
): number[] {
	if (!data || data.length === 0) {
		return [0, 5000, 10000, 15000, 20000];
	}

	// Get all the relevant values
	const values = data.map((d) => d[dataKey] ?? 0);

	const minValue = Math.min(...values);
	const maxValue = Math.max(...values);

	// If the entire data set is 0 or empty, return a default
	if (minValue === 0 && maxValue === 0) {
		return [0, 5000, 10000, 15000, 20000];
	}

	// Determine the step size based on whichever absolute value is larger
	const stepSize = findBaseStep(
		Math.abs(maxValue) > Math.abs(minValue) ? maxValue : minValue,
	);

	// Round min & max to multiples of stepSize
	const yMin = Math.floor(minValue / stepSize) * stepSize;
	const yMax = Math.ceil(maxValue / stepSize) * stepSize;

	// We want exactly 5 ticks => 4 intervals
	const interval = (yMax - yMin) / 4;

	return [
		yMin,
		yMin + interval,
		yMin + interval * 2,
		yMin + interval * 3,
		yMax,
	];
}

export const PnlChartComponent = () => {
	const [activeTab, setActive] = useState<TabsType>('pnl');
	const appState = useSelector((state: RootState) => state.app);
	const dispatch = useDispatch();
	const chartData = appState.chart.value;

	const targetDate = moment
		.utc()
		.endOf('day')
		.subtract(appState.chart.frequency, 'days');

	let previousValue = 0;
	for (let i = 0; i < chartData.length - 1; i++) {
		if (moment.utc(chartData[i].createdAt).isAfter(targetDate)) {
			previousValue = i === 0 ? 0 : chartData[i - 1]?.pnl;
			break;
		}
	}

	function generateDateRangeData() {
		const newData: PortfolioData[] = [];
		chartData.forEach((d) => {
			if (moment.utc(d.createdAt).isAfter(targetDate)) {
				// Subtract the previousValue so the data from that date is "relative"
				newData.push({ ...d, pnl: d.pnl - previousValue });
			}
		});
		return newData;
	}

	const formattedData = generateDateRangeData();

	const CustomTooltip = ({
		active,
		payload,
	}: {
		active?: boolean;
		payload?: any;
		label?: string;
	}) => {
		if (active && payload && payload.length) {
			return (
				<CustomCard>
					{payload[0].payload.createdAt && (
						<p className="label">
							{`Day: ${moment(
								payload[0].payload.createdAt,
							).format('DD-MMM-YYYY HH:MM')}`}
						</p>
					)}
					<p className="label">
						{`Value: $${payload[0].payload[
							activeTab === 'pnl' ? 'pnl' : 'portfolio_value'
						]
							.toFixed(2)
							.toLocaleString()}`}
					</p>
				</CustomCard>
			);
		}
		return null;
	};

	// Fetch initial data
	const fetchStat = useCallback(async () => {
		try {
			const { data } = await axios.get(
				'https://apis.vcred.trade/api/dudu/data/charts',
			);
			const temp = data
				.sort((a: PortfolioData, b: PortfolioData) => {
					return (
						moment(a.createdAt).valueOf() -
						moment(b.createdAt).valueOf()
					);
				})
				.map((d: Omit<PortfolioData, 'pnl'> & { pnl: string }) => {
					return {
						...d,
						pnl: ParseFloat(d.pnl),
					};
				});
			dispatch(appActions.setChartData(temp));
		} catch (e) {
			// handle error
		}
	}, [dispatch]);

	useEffect(() => {
		fetchStat();
	}, [fetchStat]);

	// Handle time frame changes (e.g. 7D, 30D, etc.)
	const handleTimeFrameChange = (event: SelectChangeEvent<number>) => {
		dispatch(appActions.setChartFrequency(event.target.value as number));
	};

	return (
		<>
			<Box
				sx={{
					p: 2,
					backgroundColor: '#eae0cc',
					color: '#ffffff',
					borderRadius: 2,
					width: '100%',
					maxWidth: 600,
					margin: 'auto',
				}}
			>
				{/* Top Controls: Tabs (PnL vs. AV) and Frequency Selector */}
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box display="flex" alignItems="center" mb={2}>
						{Tabs.map((tab) => {
							return (
								<CustomChip
									key={tab.id}
									isSelected={tab.value === activeTab}
									label={tab.id}
									onClick={() => setActive(tab.value)}
									deleteIcon={
										tab.value === activeTab ? (
											<CheckIcon sx={{ color: '#000' }} />
										) : (
											<></>
										)
									}
									onDelete={() => {
										// no-op, used just for Chip styling
									}}
								/>
							);
						})}
					</Box>
					<FormControl
						variant="outlined"
						size="small"
						sx={{ minWidth: 100 }}
					>
						<Select
							value={appState.chart.frequency}
							onChange={handleTimeFrameChange}
							size="small"
							displayEmpty
						>
							{FREQUENCY.map((d) => (
								<MenuItem value={d.value} key={d.name}>
									{d.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>

				{/* Chart Container */}
				<StyledBox position="relative" height={250} width={450}>
					<ResponsiveContainer>
						<LineChart
							data={
								activeTab === 'pnl'
									? [
											// Dummy initial data point so PnL starts from 0
											{
												createdAt: '',
												day_stamp: '',
												interest_value: '',
												pnl: 0,
												portfolio_value: 0,
												volume_trade: '0',
												_id: '',
											},
											...formattedData,
									  ]
									: formattedData
							}
							margin={{
								top: 20,
								right: 20,
								left: -10,
								bottom: 20,
							}}
						>
							<CartesianGrid
								stroke="#aaa"
								strokeDasharray="3 3"
								horizontal={false}
								vertical={false}
							/>
							<XAxis
								dataKey="createdAt"
								tick={{ fill: '#000', fontSize: 12 }}
								axisLine={true}
								tickLine={false}
								tickCount={
									appState.chart.frequency > 7
										? 10
										: appState.chart.frequency - 2
								}
								tickFormatter={() => ''}
							/>
							<YAxis
								tick={{ fill: '#000', fontSize: 12 }}
								axisLine={true}
								tickLine={false}
								/* Dynamically compute exactly 5 ticks:
								 * [yMin, yMin+interval, yMin+2*interval, yMin+3*interval, yMax].
								 */
								ticks={computeYAxisTicks(
									activeTab === 'pnl'
										? [
												// If you're adding the dummy data for PnL, add it in the data we pass to compute ticks
												{
													createdAt: '',
													day_stamp: '',
													interest_value: '',
													pnl: 0,
													portfolio_value: 0,
													volume_trade: '0',
													_id: '',
												},
												...formattedData,
										  ]
										: formattedData,
									activeTab === 'pnl'
										? 'pnl'
										: 'portfolio_value',
								)}
								dataKey={
									activeTab === 'pnl'
										? 'pnl'
										: 'portfolio_value'
								}
								tickFormatter={(value) =>
									formatNumberToCurrencyView(value)
								}
							/>
							<Tooltip
								content={(props) => (
									<CustomTooltip {...props} />
								)}
							/>
							<Line
								type="step"
								dataKey={
									activeTab === 'pnl'
										? 'pnl'
										: 'portfolio_value'
								}
								stroke="#b9886d"
								strokeWidth={2}
								dot={false}
								activeDot={{ r: 4 }}
							/>
						</LineChart>
					</ResponsiveContainer>
				</StyledBox>
			</Box>
		</>
	);
};
