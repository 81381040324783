import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { appActions } from 'store/app';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

const Container = styled('div')`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Wrapper = styled('div')`
	max-width: 1000px;
	margin-bottom: 100px;
	padding: 15px;
`;

const Text = styled(Typography)`
	white-space: pre-wrap;
	margin: 20px 0 10px 0;
	font-weight: 600;
`;

const SmallText = styled(Typography)`
	white-space: pre-wrap;
	margin: 7px 0 5px 0;
`;

export const TermsAndCondition = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			appActions.setLoading({
				loading: false,
				message: 'Please Wait...',
			}),
		);
	}, [dispatch]);

	return (
		<Container>
			<Wrapper>
				<Text variant={'h4'} alignSelf={'center'}>
					Terms and Conditions
				</Text>
				<Box height={20} />
				<Typography style={{ whiteSpace: 'pre-line' }}>
					{`By participating in this beta testing, you agree to the following terms and conditions:

					These Terms of Service (the “Terms”) constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”), and VCRED Ltd. British Virgin Islands (“VCRED,” “we,” or “us”), concerning your access to and use of the https://dudu.rndm.io website (the “Website”),RNDM smart contracts, and our related websites, hosted applications, AI models (collectively, the “Services”).  By accessing or using the Services, you agree that you have read, understand, and accept all of the terms and conditions contained in the Terms.  If you do not agree to all of the terms and conditions of the Terms, you may not access or use the Services.

					Amendment of the Terms:  We may amend or modify the Terms at any time by posting the revised agreement on the Website and/or providing a copy to you (“Revised Terms”).  Such Revised Terms shall be effective as of the time the Revised Terms are posted.  Your continued use of the Services after the posting of Revised Terms constitutes your acceptance of such Revised Terms.  If you do not agree with any such modifications, your sole and exclusive remedy is to terminate your use of the Services.
`}
					<SmallText>
						* You represent and warrant that you are not a person
						and/or not representing a legal entity which resides in,
						is a citizen of, is located or incorporated in, has
						registered office in or is otherwise subject to the
						jurisdiction of the United States of America or any
						Prohibited Localities, and that you are not a Restricted
						Person (each as defined in these Terms). There are no
						exceptions, and you represent and warrant that you are
						not using a VPN and/or are not otherwise circumventing
						this prohibition.
					</SmallText>
					<SmallText>
						* You acknowledge that the Site Operator is not
						authorized or regulated by any financial market
						regulatory authority, including the United Kingdom
						Financial Conduct Authority (FCA). Nothing on this
						website is intended to constitute marketing or
						promotion, and the content of this website is not
						intended for UK consumers. Consumer protections under UK
						regulations do not apply, and you agree to waive any
						protection afforded under FCA regulations. The Site
						Operator does not provide services to UK residents.
					</SmallText>
					<SmallText>
						* You must be permitted to use the Site and the RNDM
						Smart Contract System under applicable laws, and you
						must always comply with all applicable laws. Such
						applicable laws may vary depending on where you are
						domiciled, located, incorporated, operate out of, etc.,
						and only you are responsible for ensuring full
						compliance.
					</SmallText>
					<SmallText>
						* You acknowledge and agree that the Site is provided
						for informational purposes only and neither the Site
						Operator nor any individual or group of the VCRED,RNDM
						project team members, contributors, or similar are in
						control of or, have custody over your funds, the ability
						or duty to transact on your behalf or the power to
						reverse your transactions.
					</SmallText>
					<SmallText>
						* You acknowledge and agree that the Site does not
						constitute any financial advice and is only being
						provided 'as is' and 'as available' without any
						representation, warranties or guarantees whatsoever.
					</SmallText>
					<SmallText>
						* You acknowledge and agree that there is no legal or
						factual relationship between you and the Site Operator
						and/or any individual or group of the VCRED project team
						members, contributors, or similar relating to VCRED. The
						VCRED Smart Contract System may be accessed through a
						variety of means and access requires third party
						software (e.g., wallets) for which you are fully
						responsible.
					</SmallText>
					<SmallText>
						* You acknowledge and agree that blockchain and DeFi,
						including the VCRED Smart Contract System, are of novel
						and experimental nature and are associated with a
						variety of risks, which you have familiarized yourself
						with and accepted. The Site and the VCRED Smart Contract
						System are available 'as is' and 'as available' without
						any representation, warranties or guarantees whatsoever
						and the Site Operator is not, to the maximum extent
						permitted under applicable law, liable for any damages
						incurred by you in connection with the use of the Site
						or the VCRED Smart Contract System.
					</SmallText>
					<SmallText>
						* You, to the maximum extent permitted under applicable
						law, release all present and future claims against the
						Site Operator, and against any individual or group of
						VCRED project team members, contributors or similar
						related to the use of the VCRED Smart Contract System.
					</SmallText>
					<SmallText>
						* You acknowledge that the Services incorporate
						experimental and novel technology and that the use of
						such technology involves a high degree of risk. For
						example, there are numerous reasons the Services and/or
						Protocol could fail in an unexpected way, resulting in
						the total and absolute loss of your crypto assets. You
						hereby agree that you assume all risks in connection
						with your use of the Services and expressly waive and
						release VCRED from any and all liability, claims, causes
						of action or damages arising out of or in any way
						relating to you obtaining or using Services.
					</SmallText>
					<SmallText>
						* By entering into these Terms, you waive your potential
						right to participate in class actions and agree that any
						disputes are resolved pursuant to binding, confidential
						arbitration, whereby your potential right to a jury
						trial is waived. Please see dispute resolution terms
						below: Dispute Resolution
					</SmallText>
				</Typography>
				<Typography style={{ whiteSpace: 'pre-line' }}>
					<Text variant={'h6'}>1.1 Dispute Notice</Text>
					In the event that any dispute, controversy, or claim arises
					out of or in connection with these terms and conditions
					including the breach, termination, existence or invalidity
					thereof (a “Dispute”), any Party may serve formal written
					notice on the other Party that a Dispute has arisen (“Notice
					of Dispute”). The Parties shall use all reasonable efforts
					for a period of 30 (thirty) days from the date on which the
					Notice of Dispute is served (or such longer period as may be
					agreed in writing between the Parties) to resolve the
					Dispute on an amicable basis.
					<Text variant={'h6'}>1.2 Binding Arbitration</Text>
					If the Parties are unable to resolve the Dispute by amicable
					negotiation within the time period referred to in Clause 1.1
					(Dispute Notice), or such period as extended pursuant to
					that clause, the Dispute shall be finally settled through
					arbitration under BVI IAC Rules in force when the Notice of
					Arbitration is submitted in accordance with the following
					provisions of this Clause 1.2 (Binding Arbitration): 1.1.1
					The number of arbitrators shall be three. Each Party to the
					Dispute shall appoint 1 (one) arbitrator within 30 (thirty)
					days of the commencement of the arbitration, and the 2 (two)
					arbitrators so appointed shall select the presiding
					arbitrator within 30 (thirty) days after the latter of the 2
					(two) arbitrators has been appointed by the Parties to the
					Dispute. 1.1.2 The arbitration proceedings shall take place
					in British Virgin Islands unless the Parties agree
					otherwise;. 1.1.3 The British Virgin Islands shall be the
					seat of the arbitration; 1.1.4 The language of the
					arbitration, including arguments and briefs, shall be
					English. 1.1.5 The Parties shall have the right to seek
					interim relief from a court of competent jurisdiction, at
					any time before or after the arbitrators have been
					appointed, up until the arbitrators have made their final
					determination.
					<Text variant={'h6'}>
						2. Governing Law and Submission to Jurisdiction
					</Text>
					2.1 This Agreement and the documents to be entered into
					pursuant to it and any non-contractual obligations arising
					out of or in connection with the terms and conditions and
					such documents shall be governed by and construed in
					accordance with laws of British Virgin Islands. 2.2 Each of
					the Parties irrevocably submits to the non-exclusive
					jurisdiction of the courts of British Virgin Islands to
					support and assist the arbitration process pursuant to
					Clause 1 (Dispute Resolution), including if necessary the
					grant of interlocutory relief pending the outcome of that
					process.
					<Text variant={'h6'}>Privacy policy:</Text>
					We use the Personal Information that we have about you to
					enforce the Terms and Service for the Site and this Privacy
					Policy; in connection with rewards programs; and to analyze
					Site usage to improve this Site.
				</Typography>
			</Wrapper>
		</Container>
	);
};
