import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

const Container = styled('div')`
	width: 100%;
	padding: 10px;
	background: #e6e6e966;
	border-radius: 4px;
	border: 2px solid #b9886d;
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const CoinContainer = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background: #eae4d9;
	border-radius: 16px;
	padding: 6px 10px;
	width: min-content;
`;

const StyledRow = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 10px;
	justify-content: space-between;
`;

export const AmountBox: React.ComponentType<{
	text: string;
	leftTxt?: string;
	rightTxt?: string;
	currency: { icon: string; name: string };
}> = ({ leftTxt, currency, rightTxt }) => {
	return (
		<Container>
			<Row>
				<StyledRow>
					<Typography
						display={'inline'}
						variant={'body1'}
						fontWeight={600}
					>
						In Vault {rightTxt || ''}
					</Typography>
					<CoinContainer>
						<img
							src={currency.icon}
							height={25}
							width={25}
							alt={currency.name}
						/>
						<Box width={10} />
						<Typography variant={'body1'} color={'#000'}>
							{currency.name}
						</Typography>
					</CoinContainer>
				</StyledRow>
			</Row>
			<Row>
				<Typography
					variant={'body1'}
					color={'#666677'}
					style={{ marginLeft: 14 }}
				>
					{leftTxt || ''}
				</Typography>
			</Row>
		</Container>
	);
};
