import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { Network } from 'config';
import { _chains } from '@rainbow-me/rainbowkit/dist/config/getDefaultConfig';

export const baseNetwork = {
	id: 8453,
	name: Network.Base,
	nativeCurrency: {
		name: 'ETH',
		symbol: 'ETH',
		decimals: 18,
	},
	rpcUrls: {
		default: {
			http: ['https://mainnet.base.org'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Base',
			url: 'https://basescan.org',
		},
	},
};

const mainNet: _chains = [baseNetwork];

export const wagmiConfig = getDefaultConfig({
	appName: 'RNDM',
	projectId: 'YOUR_PROJECT_ID',
	chains: mainNet,
	ssr: false,
});
